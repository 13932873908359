import { UAParser } from 'ua-parser-js';
import { Framework } from './_framework.js';

let uaResult 	= UAParser();
let device 		= uaResult.device;
let browser 	= uaResult.browser.name.replace(/\s+/g, "").toLowerCase();
let $html 		= document.documentElement;
let isPage 		= "";
$html.classList.add(browser);
//console.log(uaResult);
$html.classList.add( uaResult.os.name.split(" ")[0].toLowerCase() );

var agent = window.navigator.userAgent.toLowerCase();
var ipad = agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1 && 'ontouchend' in document;

let cls = [];
if(device.type === "mobile" || ipad){
	cls.push("mobile");
	$html.classList.add("mobile");
	if(device.model === "iPhone"){
		cls.push(device.model);
	}else{
		if(ipad){
			cls.push("iPad");
		}else{
			cls.push("android");
		}
	}
}else{
	cls.push("pc");
}
const userAgent = navigator.userAgent;
if (navigator.maxTouchPoints) {
	cls.push("hasTouch");
}
cls.forEach( (c) => {
	$html.classList.add(c);
});
document.addEventListener('DOMContentLoaded', function () {
	let fw = new Framework();
});