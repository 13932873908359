class MarqueeBlock {
	constructor(target){
		this.target = target;
		this.imgs 	= target.querySelectorAll("figure");
		this.max 	= this.imgs.length;
		this.left 	= 0;
		this.isHover = false;
		//console.log(this.target, this.max);
		this.move();
		this.nav();
	}
	move(){
		
		let half = this.max/2;
		let $start = this.imgs[half];
		let startPos = $start.getBoundingClientRect().left - this.left;
		
		if( !this.isHover ){
			this.left -= 2;
		}else{
			this.left -= 1;
		}
		//console.log(this.left, startPos)
		if(this.left <= -startPos){
			this.left = 0;
		}
		this.target.style.transform = `translate3d(${this.left}px, 0, 0)`;
	}
	nav(){
		let $this = this;
		this.target.addEventListener("mouseenter", {$this: this, handleEvent: $this.toggleEnter}, false);
		this.target.addEventListener("mouseleave", {$this: this, handleEvent: $this.toggleEnter}, false);
	}
	toggleEnter(e){
		let $target = this.$this.target;
		
		if( !$target.classList.contains("hover") ){
			$target.classList.add("hover");
			this.$this.isHover = true;
		}else{
			$target.classList.remove("hover");
			this.$this.isHover = false;
		}
	}
}
export let marquee = {
	arry: [],
	timer: null,
	duration: 33,
	init: function(){
		let $blocks = document.querySelectorAll(".c-slide_imgs");
		$blocks.forEach( ($blk, index) => {
			let $original = $blk.querySelectorAll("figure");
			$original.forEach( ($o, index) => {
				let cloneNode = $o.cloneNode(true);
				$blk.appendChild(cloneNode);
			});
			marquee.arry[index] = new MarqueeBlock($blk);
		});
		marquee.timer = setInterval( marquee.update, marquee.duration);
	},
	update: function(){
		marquee.arry.forEach( (m) => {
			m.move();
		});
	}
}