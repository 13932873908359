import Lenis from 'lenis'
import { object } from './_obj.js';
let Block = {
	top: 0,
	show: 0
}
/*
座標
elem.getBoundingClientRect()
*/
let $this;
export class Scroll {
	constructor(){
		$this = this;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		this.observer;
		this.lenis = new Lenis();
		
		this.reloadObserver();
		this.startLenis();
	}
	startLenis(){
		let $this = this;
		$this.lenis.on('scroll', (e) => {
			//console.log(e)
		});
		function raf(time) {
			$this.lenis.raf(time)
			requestAnimationFrame(raf)
		}
		
		requestAnimationFrame(raf)
	}
	reloadObserver(){
		let $this = this;
		if($this.observer){
			$this.observer.disconnect();
		}
		this.setObserver(null);
	}
	
	setObserver(root){
		let $this = this;
		let $animates = document.querySelectorAll("[data-animate]");
		if( $animates ){
			//DOMにdata-repeat="1"で画面外にいったときにanimateのclassを削除
			let AnimateOptions = {
				root: root ? root : null,
				rootMargin: "-33% 0px",
				threshold: 0
			}
			//data-animate用observer
			$this.observer = new IntersectionObserver($this.checkAnimate, AnimateOptions);
			//console.log($animates, AnimateOptions, $this.observer);
			$animates.forEach( item => {
				$this.observer.observe(item);
			});
		}
		//other observer
		/*
		const elements = document.querySelectorAll('.watch-elements');
		
		// 要素ごとに異なる rootMargin を設定して監視
		elements.forEach((element, index) => {
		  // rootMargin を要素ごとに設定
		  let rootMarginValue;
		  if (index % 2 === 0) {
			rootMarginValue = '10px';
		  } else {
			rootMarginValue = '20px';
		  }
		
		  // Intersection Observer インスタンスを作成
		  const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				console.log(`Element ${entry.target.id} is in view!`);
			  }
			});
		  }, { rootMargin: rootMarginValue });
		
		  // 要素を監視
		  observer.observe(element);
		});
		*/
	}
	checkAnimate(items){
		items.forEach( (item) => {
			if( item.isIntersecting ){
				//console.log("interSectiong:", item.target);
				startAnimate( item.target );
			}else{
				if( item.target.getAttribute("data-repeat") === "1" ){
					deleteAnimate(item.target);
					
				}
			}
		});
		
		function startAnimate( $target ){
			if( !$target.classList.contains("animate") ){
				$target.classList.add("animate");
			}
		}
		function deleteAnimate( $target ){
			if( $target.classList.contains("animate") ){
				$target.classList.remove("animate");
			}
		}
	}
	getTop($dom, offset){
		let t = Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		return [ t, Math.floor( t + offset )]
	}
	
	scrollToPos(posY){
		console.log("scroll to > ", posY);
		window.scrollTo({
			top: posY,
			behavior: "smooth"
		});
	}
}

export let ScrollTo = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollTo;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		//console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		let max = y - window.pageYOffset;
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			//let progress = 1 - current/duration;
			let progress = current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			let currentPos = max*easeProgress + $this.yOffset;
			//console.log(max*easeProgress, window.pageYOffset);
			window.scrollTo(0, currentPos );
		}, 33);
	}
}