import { shareSNS } from './lib/_share.js';
import { Scroll } from './lib/_scroll.js';
import Cookies from 'js-cookie';

export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		let isPage = $body.getAttribute("data-page");
		
		if(isPage != "top"){
			common.startScroll();
		}
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener('load', common.updateVideoSource);
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	startScroll: function(){
		common.scrl = new Scroll();
	},
	nav: ()=>{
		let $btnMenu 	= document.querySelector("[data-menu]");
		if($btnMenu){
			//single handlers
			const eventListeners = [
				{ element: $btnMenu, type: "click", handler: common.toggleMenu}
			]
			eventListeners.forEach( ( el ) => {
				if(el.element){
					el.element.removeEventListener(el.type, el.handler, false);
					el.element.addEventListener(el.type, el.handler, false);
				}
			});
		}
		window.addEventListener("keyup", function(e){
			let $html = document.documentElement;
			let keyCode = e.keyCode;
			console.log(keyCode)
			if(keyCode === 71){
				if( !$html.classList.contains("show_grid") ){
					$html.classList.add("show_grid");
				}else{
					$html.classList.remove("show_grid");
				}
			}else if(keyCode === 65){
				Cookies.remove('visited_kon');
			}
		}, false);
	},
	toggleMenu: function(){
		let $html = document.documentElement;
		if( !$html.classList.contains("show_menu") ){
			$html.classList.add("show_menu");
		}else{
			$html.classList.remove("show_menu");
		}
	},
	updateVideo: function(){
		let $video = document.querySelector("#videoPlayer");
		if( $video ){
			let $source 		= $video.querySelector("#videoSource");
			let currentSource 	= $source.getAttribute("src");
			let newSource = "";
			let now = new Date();
			let time = now.getTime();
			let path = "/wp-content/themes/kon_theme/assets/movie/"
			if (window.matchMedia('(min-width: 864px)').matches) {
				newSource = path + "fv_pc.mp4?v=" + time;
			}else{
				newSource = path + "fv_sp.mp4?v=" + time;
			}
			if (currentSource !== newSource) {
				$video.pause();
				$source.setAttribute("src", newSource);
				$video.load();
				$video.play();
				$video.removeEventListener("timeupdate", common.videoProgress, false);
				$video.addEventListener("timeupdate", common.videoProgress, false);
			}
		}
	},
	videoProgress: function(e){
		let $this = this;
		let $video = document.querySelector("#videoPlayer");
		let time = $video.currentTime;
		let duration = $video.duration;
		
		if( time >= duration - 0.3 ){
			$video.currentTime = 0;
			
			$video.play();
		}
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		let $html 	= document.documentElement;
		
		let $movies = document.querySelectorAll("article .c-movie");
		if( $movies ){
			$movies.forEach( ($movie) => {
				let $iframe = $movie.querySelector("iframe");
				let w = $iframe.getAttribute("width");
				let h = $iframe.getAttribute("height");
				$movie.style.aspectRatio = `${w} / ${h}`;
			});
		}
		if (window.matchMedia('all and (min-width : 768px)').matches) {
			if( $html.classList.contains("show_menu") ){
				$html.classList.remove("show_menu");
			}
		}
		
		addressbarH();
		
		common.getScrollBar();
		
		if( common.beforeW != winW){
			common.updateVideo();
		}
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	getScrollBar: function(){
		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	}
}
