import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';
import { marquee } from './lib/_marquee.js';
import { catchWord } from './lib/_catch.js';
import { splash } from './lib/_splash.js';
import { mouseStalker } from './lib/_mouse_stalker.js';
import { form } from './lib/_form.js';

export class Framework {
	constructor(){
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		console.log(this.pageinfo);
	}
	
	checkURL(){
		this.changeContent();
	}
		
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		common.init();
		let isPage = document.querySelector("body").getAttribute("data-page");
		//console.log(isPage);
		mouseStalker.init();
		if( isPage === "top" ){
			marquee.init();
			catchWord.init();
			splash.init();
		}else{
			form.init();
		}
		/*
		let imgArry = [
			"/assets/images/a.jpg",
			"/assets/images/b.jpg"
		];
		preload.startLoad(imgArry).then(res => {
		});
		
		function addN(n){
			let num = n + 1;
			return new Promise( resolve => {
				setTimeout(function(){
					resolve(num)
				}, 1000);
			});
		}
		async function getNum(){
			let result = await addN(199);
			return result;
		}
		getNum().then( (res) => {
			console.log(res)
		});
		*/
		common.resize();
		common.nav();
	}
	
}