export let catchWord = {
	count: 0,
	max: 0,
	target: null,
	list: [],
	timer: null,
	startTime: 0,
	nextTime: 0,
	duration: 3000,
	frameID: 0,
	enTextDom: null,
	ulList: null,
	init: function(){
		catchWord.target = document.querySelector(".c-catch");
		catchWord.enTextDom = document.querySelector(".lead_inner p.en");
		if( catchWord.target ){
			catchWord.count = 0;
			catchWord.list 	= catchWord.target.querySelectorAll("ul li");
			catchWord.max 	= catchWord.list.length;
			catchWord.start();
		}
	},
	start: function(){
		let cw = catchWord;
		cw.startTime = cw.getNow();
		cw.frameID = requestAnimationFrame(cw.update);
	},
	resize: function(){
		if (window.matchMedia('(min-width: 768px)').matches) {
			let h = catchWord.enTextDom.clientHeight;
			catchWord.target.style.top = h + "px";
		}else{
			catchWord.target.style.top = "";
		}
	},
	update: function(){
		let cw = catchWord;
		cw.resize();
		let current = cw.getNow();
		let diff = current - cw.startTime;
		if( diff >= cw.duration ){
			cw.count++;
			if(cw.count >= cw.max){
				cw.count = 0;
			}
			cw.startTime = cw.getNow();
			cw.nextWord();
		}
		cw.frameID++;
		cw.frameID = requestAnimationFrame(cw.update);
	},
	nextWord: function(){
		let cw = catchWord;
		let c = cw.count;
		let b = cw.count - 1;
		b = b < 0 ? cw.max-1 : b;
		cw.list.forEach( ($l, index) => {
			if( index === c){
				$l.classList.add("active");
			}else if( index === b ){
				$l.classList.remove("active");
				$l.classList.add("disable");
				$l.addEventListener("transitionend", (e)=>{
					//console.log(e);
					$l.classList.remove("disable");
				}, {
					once: true
				});
			}
		});
		//console.log(b, c);
	},
	getNow: function(){
		let now = new Date();
		return now.getTime();
	}
}