export let form = {
	arry: [],
	requires: [],
	target: "",
	init: function(){
		form.requires = document.querySelectorAll("dl.require");
		form.target = document.querySelector(".label_submit");
		if( form.requires.length > 0 ){
			//console.log("form init");
			form.nav();
			setTimeout(function(){
				form.check();
			}, 500);
		}
	},
	nav: function(){
		$inputs 	= document.querySelectorAll(`.require input[type="text"]`);
		$email 		= document.querySelectorAll(`.require input[type="email"]`);
		$textarea 	= document.querySelectorAll(`.require textarea`);
		console.log($inputs, $email)
		const multipleEventListeners = [
			{ element: $inputs, type: "change", handler: form.check},
			{ element: $email, type: "change", handler: form.check},
			{ element: $textarea, type: "change", handler: form.check}
		];
		multipleEventListeners.forEach( ( els ) => {
			if(els.element){
				els.element.forEach( (element) => {
					element.removeEventListener(els.type, els.handler, false);
					element.addEventListener(els.type, els.handler, false);
				})
				
			}
		});
	},
	check: function(){
		form.arry = [];
		form.requires.forEach( ($f) => {
			let $input = $f.querySelector(`input[type="text"]`);
			let $email = $f.querySelector(`input[type="email"]`);
			let $textarea = $f.querySelector(`textarea`);
			let val = "";
			if($input){
				val = $input.value;
			}else if($email){
				val = $email.value;
			}else if($textarea){
				val = $textarea.value;
			}
			if( val != "" ){
				form.arry.push(1);
			}else{
				form.arry.push(0);
			}
		});
		console.log(form.arry, form.target);
		if( form.arry.includes(0) ){
			if( form.target.classList.contains("active") ){
				form.target.classList.remove("active");
			}
		}else{
			if( !form.target.classList.contains("active") ){
				form.target.classList.add("active");
			}
		}
	}
}